import React, { Component } from "react";
import { Link } from "react-router";

import { ajax } from "../../../../network/Ajax";

import RankingSchemePicker from "./RankingSchemePicker";
import ResearcherRankingTable from "./ResearcherRankingTable";
import UniRankingTable from "./UniRankingTable";
import RankingComingSoon from "./RankingComingSoon";

class Ranking extends Component {
  constructor(props) {
    super(props);

    this.state = { index: 0, schemes: null, schemeType: "vwl" };

    this.setWeightingScheme = this.setWeightingScheme.bind(this);
  }

  componentDidMount() {
    const rankingType = this.props.params.type;

    this.fetchWeightingSchemes(rankingType);
    // Error handling? If no selection can be loaded the rest of the page is useless anyway.
  }

  fetchWeightingSchemes(rankingType) {
    var component = this;
    ajax(
      "get",
      "/api/v1/ranking/" + rankingType + "/weightingschemes",
      (response) => component.setState({ schemes: response }),
    );
    this.setState({ schemeType: rankingType });
  }

  componentDidUpdate() {
    const rankingType = this.props.params.type;
    if (this.state.schemeType != rankingType) {
      this.fetchWeightingSchemes(rankingType);
    }
  }

  setWeightingScheme(schemeIndex) {
    this.setState({ index: schemeIndex });
  }

  render() {
    var show_vintage_warning_for = "none";

    var currentRanking = this.props.params.category;
    var rankingType = this.state.schemeType;

    var currentIndex = this.state.index;
    var schemes = this.state.schemes;

    var table = null;

    var dontShowRanking = "";

    if (schemes && dontShowRanking !== rankingType) {
      if (currentRanking == "university" || currentRanking == "institute") {
        table = (
          <UniRankingTable
            pointsField={schemes[currentIndex].points}
            rankField={schemes[currentIndex].name}
            vintage={null}
            source={"api/v1/ranking/" + rankingType + "/" + currentRanking}
            ranking={currentRanking}
          />
        );
      } else {
        table = (
          <ResearcherRankingTable
            pointsField={schemes[currentIndex].points}
            rankField={schemes[currentIndex].name}
            vintage={null}
            source={"api/v1/ranking/" + rankingType + "/" + currentRanking}
            ranking={currentRanking}
            percentBins={[1, 5, 10, 25, 50, 100]}
          />
        );
      }
    } else if (dontShowRanking === rankingType) {
      table = <RankingComingSoon></RankingComingSoon>;
    }

    return (
      <div className="container content">
        <nav className="navbar navbar-inverse">
          <button
            type="button"
            className="pull-left navbar-toggle"
            data-toggle="collapse"
            data-target="#rankingNav"
          >
            Choose ranking
          </button>
          <div className="collapse navbar-collapse" id="rankingNav">
            <ul className="nav navbar-nav">
              <li>
                <Link
                  to={"/ranking/" + rankingType + "/authors"}
                  activeClassName="active"
                >
                  Author Ranking
                </Link>
              </li>
              <li>
                <Link
                  to={"/ranking/" + rankingType + "/young"}
                  activeClassName="active"
                >
                  {rankingType == "vwl"
                    ? "Young Economist Ranking"
                    : "Under 40 ranking"}
                </Link>
              </li>
              <li>
                <Link
                  to={"/ranking/" + rankingType + "/lifetime"}
                  activeClassName="active"
                >
                  Lifetime Ranking
                </Link>
              </li>
              <li>
                <Link
                  to={"/ranking/" + rankingType + "/university"}
                  activeClassName="active"
                >
                  University Ranking
                </Link>
              </li>
              {rankingType == "vwl" && (
                <li>
                  <Link
                    to={"/ranking/" + rankingType + "/institute"}
                    activeClassName="active"
                  >
                    Institute Ranking
                  </Link>
                </li>
              )}
            </ul>
            <ul className="nav navbar-nav navbar-right">
              <li>
                <Link
                  to={
                    "/ranking/" +
                    (rankingType == "vwl" ? "bwl" : "vwl") +
                    "/" +
                    (currentRanking == "institute" ? "authors" : currentRanking)
                  }
                >
                  To {rankingType == "vwl" ? "BWL" : "VWL"} version
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        {(rankingType == "bwl" && currentRanking == "authors") ? (
        <div className="row">
          <div className="panel panel-warning">
            <div className="panel-heading">
              <h3 className="panel-title">Showing 2022 results</h3>
            </div>
            <div className="panel-body">
              The rankings currently displayed are still the <strong>BWL2022 results</strong>. The updated BWL2024 rankings will be uploaded <strong>within 4 weeks</strong> after Wirtschaftswoche publications.
            </div>
          </div>
        </div>) : null}
        <div className="row">
          <div className="rankingDescription col-xs-12 col-md-6 well">
            {DESCRIPTIONS[rankingType][currentRanking]}
          </div>
          <RankingSchemePicker
            weightingSchemes={this.state.schemes}
            index={currentIndex}
            setScheme={this.setWeightingScheme}
          />
        </div>
        {show_vintage_warning_for == rankingType ? (
          <div className="row">
            <div className="vintage_warning">
              Note: The table below shows the results of the ranking 2019.
              <br />
              An update with the results of 2021 is coming soon.
            </div>
          </div>
        ) : null}
        <div className="row">{table}</div>
      </div>
    );
  }
}

const DESCRIPTIONS = {
  vwl: {
    authors: (
      <p>
        The following output shows the rankings of all economists in the German
        speaking countries Germany, Austria, and Switzerland and researchers who
        are born in these countries.
        <br />
        In this ranking only publications from the years 2019 to 2023 and
        forthcoming articles are considered.
        <br />
        The standard ranking is based on SJR values, there is a co-author
        correction, all journals are rated relative to the top-5 journal, the
        minimum weight for a journal is 0.025, and all journals in the EconLit
        database are considered. <br />
        For a more detailed discussion of the main weighting scheme, see
        <a href="https://www.oekonomenstimme.org/articles/1212">
          {" "}
          Ökonomenstimme article
        </a>
        .<br />
        The Wirtschaftswoche publishes our main ranking (1 – SJR weights, top-5
        journals receive weight of 1) on their{" "}
        <a href="https://www.wiwo.de/my/politik/konjunktur/vwl-ranking-das-sind-die-forschungsstaerksten-vwl-hochschulen/29544350.html">
          webpage
        </a>
        .
      </p>
    ),

    young: (
      <p>
        The following output shows the rankings of all economists under 40 years
        in the German speaking countries Germany, Austria, and Switzerland and
        researchers who are born in these countries. <br />
        In this ranking all publications from these authors are considered.
        <br />
        The standard ranking is based on SJR values, there is a co-author
        correction, all journals are rated relative to the top-5 journal, the
        minimum weight for a journal is 0.025, and all journals in the EconLit
        database are considered.
        <br />
        For a more detailed discussion of the main weighting scheme, see
        <a href="http://www.oekonomenstimme.org/artikel/2017/06/handelsblatt-ranking-20-wissenschaftlicher-flexibler-transparenter/">
          {" "}
          Ökonomenstimme article
        </a>
        .<br />
        The Wirtschaftswoche publishes our main ranking (1 – SJR weights, top-5
        journals receive weight of 1) on their{" "}
        <a href="https://www.wiwo.de/my/politik/konjunktur/vwl-ranking-das-sind-die-forschungsstaerksten-vwl-hochschulen/29544350.html">
          webpage
        </a>
        .
      </p>
    ),

    lifetime: (
      <p>
        The following output shows the lifetime rankings of all economists in
        the German speaking countries Germany, Austria, and Switzerland and
        researchers who are born in these countries.
        <br />
        In this ranking all publications from these authors are considered.
        <br />
        The standard ranking is based on SJR values, there is a co-author
        correction, all journals are rated relative to the top-5 journal, the
        minimum weight for a journal is 0.025, and all journals in the EconLit
        database are considered.
        <br />
        For a more detailed discussion of the main weighting scheme, see
        <a href="http://www.oekonomenstimme.org/artikel/2017/06/handelsblatt-ranking-20-wissenschaftlicher-flexibler-transparenter/">
          {" "}
          Ökonomenstimme article
        </a>
        .<br />
        The Wirtschaftswoche publishes our main ranking (1 – SJR weights, top-5
        journals receive weight of 1) on their{" "}
        <a href="https://www.wiwo.de/my/politik/konjunktur/vwl-ranking-das-sind-die-forschungsstaerksten-vwl-hochschulen/29544350.html">
          webpage
        </a>
        .
      </p>
    ),

    university: (
      <p>
        The following output shows the rankings of the best 25 universities in
        the German speaking countries Germany, Austria, and Switzerland.
        <br />
        In this ranking the weights of all publications from all economists
        currently employed by a university are summed up for the years 2014 to
        2023.
        <br />
        The standard ranking is based on SJR weights, there is a co-author
        correction, all journals are rated relative to the top-5 journal, the
        minimum weight for a journal is 0.025, and all journals in the EconLit
        database are considered.
        <br />
        For a more detailed discussion of the main weighting scheme, see
        <a href="http://www.oekonomenstimme.org/artikel/2017/06/handelsblatt-ranking-20-wissenschaftlicher-flexibler-transparenter/">
          {" "}
          Ökonomenstimme article
        </a>
        .<br />
        The Wirtschaftswoche publishes our main ranking (1 – SJR weights, top-5
        journals receive weight of 1) on their{" "}
        <a href="https://www.wiwo.de/my/politik/konjunktur/vwl-ranking-das-sind-die-forschungsstaerksten-vwl-hochschulen/29544350.html">
          webpage
        </a>
        .
      </p>
    ),

    institute: (
      <p>
        The following output shows the rankings of the best 15 institutes in the
        German speaking countries Germany, Austria, and Switzerland.
        <br />
        In this ranking the weights of all publications from all economists
        currently employed by an institute are summed up for the years 2014 to
        2023.
        <br />
        The standard ranking is based on SJR weights, there is a co-author
        correction, all journals are rated relative to the top-5 journal, the
        minimum weight for a journal is 0.025, and all journals in the EconLit
        database are considered.
        <br />
        For a more detailed discussion of the main weighting scheme, see
        <a href="http://www.oekonomenstimme.org/artikel/2017/06/handelsblatt-ranking-20-wissenschaftlicher-flexibler-transparenter/">
          {" "}
          Ökonomenstimme article
        </a>
        .
      </p>
    ),
  },
  bwl: {
    authors: (
      <p>
        The following output shows the rankings of all scientists in business
        administration in the German speaking countries Germany, Austria, and
        Switzerland and researchers who are born in these countries. In this
        ranking only publications from the years 2018 to 2022 and forthcoming
        articles are considered. The standard ranking is based on{" "}
        <a href="https://vhbonline.org/vhb4you/jourqual/">VHB-Jourqual 3</a>{" "}
        journal list, there is a co-author correction, the journals are weighted
        as A+=1; A=0.5; B=0.25; C=0.1; D=0.05; k.R.=0.025. For a more detailed
        discussion of the main weighting scheme, see the{" "}
        <a href="/Description_BWL_Main_Journal_weights.pdf">
          description of the weighting scheme
        </a>
        . The WirtschaftsWoche publishes our main ranking (1 – VHB Jourqual 3
        journals and weights) on their{" "}
        <a href="https://www.wiwo.de/">webpage</a>.
      </p>
    ),

    young: (
      <p>
        The following output shows the rankings of all scientists in business
        administration under 40 years in the German speaking countries Germany,
        Austria, and Switzerland and researchers who are born in these
        countries. In this ranking all publications from these authors are
        considered. The standard ranking is based on{" "}
        <a href="https://vhbonline.org/vhb4you/jourqual/">VHB-Jourqual 3</a>{" "}
        journal list, there is a co-author correction, the journals are weighted
        as A+=1; A=0.5; B=0.25; C=0.1; D=0.05; k.R.=0.025. For a more detailed
        discussion of the main weighting scheme, see the{" "}
        <a href="/Description_BWL_Main_Journal_weights.pdf">
          description of the weighting scheme
        </a>
        . The WirtschaftsWoche publishes our main ranking (1 – VHB Jourqual 3
        journals and weights) on their{" "}
        <a href="https://www.wiwo.de/">webpage</a>.
      </p>
    ),

    lifetime: (
      <p>
        The following output shows the lifetime rankings of all scientists in
        business administration in the German speaking countries Germany,
        Austria, and Switzerland and researchers who are born in these
        countries. In this ranking all publications from these authors are
        considered. The standard ranking is based on{" "}
        <a href="https://vhbonline.org/vhb4you/jourqual/">VHB-Jourqual 3</a>{" "}
        journal list, there is a co-author correction, the journals are weighted
        as A+=1; A=0.5; B=0.25; C=0.1; D=0.05; k.R.=0.025. For a more detailed
        discussion of the main weighting scheme, see the{" "}
        <a href="/Description_BWL_Main_Journal_weights.pdf">
          description of the weighting scheme
        </a>
        . The WirtschaftsWoche publishes our main ranking (1 – VHB Jourqual 3
        journals and weights) on their{" "}
        <a href="https://www.wiwo.de/">webpage</a>.
      </p>
    ),

    university: (
      <p>
        The following output shows the rankings of the best 25 universities in
        the German speaking countries Germany, Austria, and Switzerland. In this
        ranking the weights of all publications from all economists currently
        employed by a university are summed up for the years 2013 to 2022. The
        standard ranking is based on{" "}
        <a href="https://vhbonline.org/vhb4you/jourqual/">VHB-Jourqual 3</a>{" "}
        journal list, there is a co-author correction, the journals are weighted
        as A+=1; A=0.5; B=0.25; C=0.1; D=0.05; k.R.=0.025. For a more detailed
        discussion of the main weighting scheme, see the{" "}
        <a href="/Description_BWL_Main_Journal_weights.pdf">
          description of the weighting scheme
        </a>
        . The WirtschaftsWoche publishes our main ranking (1 – VHB Jourqual 3
        journals and weights) on their{" "}
        <a href="https://www.wiwo.de/">webpage</a>.
      </p>
    ),
  },
};

export default Ranking;
