import React, { Component } from "react";
import { Link } from "react-router";

import FrontpagePanel from "../panels/FrontPagePanel";

const domain = "forschungsmonitoring.org";

class Home extends Component {
  render() {
    return (
      <div className="container mt-30 content">
        <h1 className="text-center">Welcome to Forschungsmonitoring</h1>

        <div className="row mt-30">
          <FrontpagePanel linkTarget="/login" linkText="Login" title="Login">
            <p>
              Log in to your account to update your profile and publications.
            </p>
          </FrontpagePanel>

          <FrontpagePanel title="Ranking">
            <p>
              Find the author ranking, the young economist ranking, the lifetime
              ranking and the university ranking.
            </p>

            <Link
              to="/ranking/vwl/authors"
              className="btn btn-primary pull-left"
            >
              VWL Ranking
            </Link>
            <Link
              to="/ranking/bwl/authors"
              className="btn btn-primary pull-right"
            >
              BWL Ranking
            </Link>
          </FrontpagePanel>
        </div>

        <div className="row mt-30">
          <FrontpagePanel title="Open a new account">
            <p>
              {"Just send us a short email to kontakt" +
                "@" +
                domain +
                " with your Name and Title. We will get back to you quickly and provide you with information to set up your account."}
            </p>
            <a
              href={
                "mailto:kontakt" + "@" + domain + "?subject=Account%20Request"
              }
              className="btn btn-primary pull-right"
            >
              Send Email
            </a>
          </FrontpagePanel>

          <FrontpagePanel title="Files">
            <p>
              Download necessary information to maintain your profile at the
              Forschungsmonitoring.
            </p>
            <div>
              <p>
                <b>VWL-Ranking:</b>
              </p>
              <a
                href={
                  process.env.PUBLIC_URL +
                  "/Description_VWL_Main_Journal_weights.pdf"
                }
              >
                Journal Weigths List Description (pdf)
              </a>
              <br />
              <br />
              <a
                href={
                  process.env.PUBLIC_URL + "/VWL_Zeitschriftenliste 2023.xlsx"
                }
              >
                Journal Weights List (xlsx)
              </a>
            </div>
            <br />
            <div>
              <p>
                <b>BWL-Ranking:</b>
              </p>
              <a
                href={
                  process.env.PUBLIC_URL +
                  "/BWL2024_Methodology.pdf"
                }
              >
                {" "}
                Journal Weights List Description (pdf)
              </a>
              <br />
              <br />
              <a href={process.env.PUBLIC_URL + "/BWL_Zeitschriftenliste_2024.xlsx"}>
                Journal Weights List (xlsx)
              </a>
            </div>
          </FrontpagePanel>
        </div>
      </div>
    );
  }
}

export default Home;
